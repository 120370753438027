<template>
  <div class="BigBody">
    <div class="TableBodyContainer">
      <div class=" CookiesRow">
        <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
          <tr>
            <th></th>
            <CookiesSortingTH @click="SortTable(['Name'])" :Name="LanguageSelector('SubArea Name', 'اسم المنطقة')" :Value="['Name']" :sortColumn="sortElment" ></CookiesSortingTH>
            <CookiesSortingTH @click="SortTable(['MainAreaName'])" :Name="LanguageSelector('Main Area', 'المنطقة الرئيسية')" :Value="['MainAreaName']" :sortColumn="sortElment" ></CookiesSortingTH>
            <CookiesSortingTH @click="SortTable(['LineName'])" :Name="LanguageSelector('Line', 'الخط')" :Value="['LineName']" :sortColumn="sortElment" ></CookiesSortingTH>
            <th v-if="Access('AreasEdit')"></th>
          </tr>
          <tr v-if="!Loading & FilterdTable.length === 0"><td colspan="10" style="padding: 1.5em 0.2em;">{{LanguageSelector('No Sub SubAreas', 'لا يوجد مناطق فرعية')}}</td></tr>
          <tr v-if="Loading"><td colspan="10" class="" style="padding: 1.5em 0.2em;"><div class="D-Flex W-100 H-100"><CookiesLoading class="M-Auto" style="place-content: center;"></CookiesLoading></div></td></tr>
          <tr v-for='(SubArea, Index) in FilterdTable' :key='(SubArea, Index)' class="CookiesGeneralTableRow">
            <td style="padding: 1em;">{{Index + 1}}</td>
            <td style="cursor: text; padding:0.2em; direction: ltr" @blur="ValueChanged(SubArea, 'Name')" :ref="SubArea['ID'] + '_Name'" :class="Access('AreasEdit') ? 'EditableCell' : ''" class="RowNoClick" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{SubArea['Name'] ? SubArea['Name']: ''}}</td>
            <td :class="Access('AreasEdit') ? '' : 'Disabled'" style=" padding:0.2em; width:70%;" class="RowNoClick"><CookiesDropDown :Type="'Object'" :Selector="'Name'" @selected="MainAreaChanged(SubArea, ('SubArea_' + SubArea['ID'] + '_MainArea'))" :Radio="true" :IndexSelect="SubAreaMainAreaSelector(SubArea)" :Options="CookiesDataAreas" :Res="'SubArea_' + SubArea['ID'] + '_MainArea'"></CookiesDropDown></td>
            <td :class="Access('AreasEdit') ? '' : 'Disabled'" style=" padding:0.2em; width:70%;" class="RowNoClick"><CookiesDropDown :Type="'Object'" :Selector="'Name'" @selected="LineChanged(SubArea, ('SubArea_' + SubArea['ID'] + '_Line'))" :Radio="true" :IndexSelect="SubAreaLineSelector(SubArea)" :Options="CookiesDataLines" :Res="'SubArea_' + SubArea['ID'] + '_Line'"></CookiesDropDown></td>
            <td>
              <div class="D-Flex">
                <CookiesIcon class="M-Auto HoverGlow" :ToolTip="LanguageSelector('Delete', 'حذف')" v-if="Access('AreasDelete')" @click="RemoveSubArea(SubArea)" style="width: 1.6em; height: 1.6em;" :ID="'Delete'"></CookiesIcon>
              </div>
            </td>
          </tr>
          <tr><td style="height:12em;"></td></tr> <!-- Last Item Drop Down Place Holder --------------------- -->
        </table> <!-- TABLE END --------------------- -->
      </div>
    </div>
</div>
</template>

<script scoped>
export default {
  mounted () {
    this.ReadSubAreas()
    this.ReadLines()
    this.ReadMainAreas()
  },
  props: {
    Search: { default: '', Type: String }
  },
  data () {
    return {
      CookiesDataLines: [],
      CookiesDataSubAreas: [],
      CookiesDataAreas: [],
      sortElment: {
        name: ['Name'],
        value: 1
      },
      Loading: false
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      // var SortedWithIDs = this.sortedTable.map(function (item) {
      //   item.Hashed_ID = '#' + item.ID
      //   return item
      // })
      this.sortedTable.forEach(item => {
        // this.ObjectFinder(this.CookiesDataLines, ['ID'], this.DeepFetcher(item, ['LineID']))
        // this.ObjectFinder(this.CookiesDataAreas, ['ID'], this.DeepFetcher(item, ['AreaID']))
        try {
          var MainAreaName = this.ObjectFinder(this.CookiesDataAreas, ['ID'], this.DeepFetcher(item, ['AreaID'])).Name
          var LineName = this.ObjectFinder(this.CookiesDataLines, ['ID'], this.DeepFetcher(item, ['LineID'])).Name
          item.MainAreaName = MainAreaName
          item.LineName = LineName
        } catch (error) {
        }
        var FilterIt = true
        // if (this.$store.state.CookiesMemory.SubAreas_Filter_Type !== item.SubArea_Type && this.$store.state.CookiesMemory.SubAreas_Filter_Type !== 'All') {
        //   FilterIt = false
        // }
        // try {
        //   if (this.$store.state.CookiesMemory.SubAreas_Filter_Page.Page_Name !== item.SubArea_Page.Page_Name && this.$store.state.CookiesMemory.SubAreas_Filter_Page.Page_Name !== 'All') {
        //     FilterIt = false
        //   }
        // } catch (error) {
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Reserved && item.SubArea_Status === 'Reserved') { // ////// Reserved
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Packaging && item.SubArea_Status === 'Packaging') { // ////// packaging
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Delivery && item.SubArea_Status === 'Delivery') { // ////// Delivery
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Delivered && item.SubArea_Status === 'Delivered') { // ////// Delivered
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Fixing && item.SubArea_Status === 'NotPaid') { // ////// Fixing
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Done && item.SubArea_Status === 'Done') { // ////// Done
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.SubArea_Filter_Canceled && item.SubArea_Status === 'Canceled') { // ////// Done
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.SubArea_Filter_PriceHigh && item.SubArea_Total_Ammount < this.$store.state.CookiesMemory.SubArea_Filter_PriceHigh_Ammount) {
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.SubArea_Filter_PriceLow && item.SubArea_Total_Ammount > this.$store.state.CookiesMemory.SubArea_Filter_PriceLow_Ammount) {
        //   FilterIt = false
        // }
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedTable () {
      var Sorted = this.CookiesDataSubAreas
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadSubAreas () {
      this.Loading = true
      this.Get('SubArea').then(response => {
        this.CookiesDataSubAreas = response
        this.Loading = false
      }, error => {
        error.CookiesError = 'Error in reading SubAreas'
        this.Loading = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadMainAreas () {
      this.Loading = true
      this.Get('Area').then(response => {
        this.CookiesDataAreas = response
        this.Loading = false
      }, error => {
        error.CookiesError = 'Error in reading Main Areas'
        this.Loading = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadLines () {
      this.Get('Area/getLine').then(response => {
        this.CookiesDataLines = response
      }, error => {
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddSubArea () {
      this.OpenDialog('NewSubArea', this.LanguageSelector('New SubArea', 'منطقة جديدة'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesEditLines () {
      this.OpenDialog('EditLines', this.LanguageSelector('Edit Lines', 'الخطوط الموجودة'), this.CookiesDataLines) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesAddLine () {
      this.OpenDialog('NewLine', this.LanguageSelector('New Line', 'خط جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenSubArea (SubArea) {
      if (event.target.classList.length > 0) {
        if ((event.target.classList.includes('RowNoClick'))) {
          return
        }
      }
      this.OpenDialog('EditSubArea', 'Edit SubArea ' + SubArea.PlateNum, SubArea) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortTable (name) {
      if (JSON.stringify(this.sortElment.name) === JSON.stringify(name)) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ValueChanged (SubArea, Attribute, Num = false) {
      if (!this.Access('AreasEdit')) {
        return
      }
      this.Console('ValueChanged', 'color: red; font-weight: 800; font-size: 2em')
      var value = this.$refs[SubArea.ID + '_' + Attribute].innerText
      if (Num) {
        value = parseFloat(value)
      }
      var originalSubArea = this.DeepCloner(SubArea)
      var NewSubArea = this.DeepCloner(SubArea)
      this.DeepChanger(NewSubArea, [Attribute], value)
      var Patch = this.CookiesPatcher(originalSubArea, NewSubArea)
      this.Post('PATCH', 'Area/EditSubArea?ID=' + SubArea.ID, Patch).then(response => {
        this.ReadSubAreas()
        this.ReadLines()
        this.ReadMainAreas()
      }, error => {
        error.CookiesError = 'Error in updating SubArea Name'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    LineChanged (SubArea, SubAreaRef) {
      var originalSubArea = this.DeepCloner(SubArea)
      var NewSubArea = this.DeepCloner(SubArea)
      this.DeepChanger(NewSubArea, ['LineID'], this.$store.state.CookiesMemory[SubAreaRef].ID)
      var Patch = this.CookiesPatcher(originalSubArea, NewSubArea)
      this.Post('PATCH', 'Area/EditSubArea?ID=' + SubArea.ID, Patch).then(response => {
        this.ReadSubAreas()
        this.ReadLines()
        this.ReadMainAreas()
      }, error => {
        error.CookiesError = 'Error in updating SubArea Line'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    MainAreaChanged (SubArea, SubAreaRef) {
      var originalSubArea = this.DeepCloner(SubArea)
      var NewSubArea = this.DeepCloner(SubArea)
      this.DeepChanger(NewSubArea, ['AreaID'], this.$store.state.CookiesMemory[SubAreaRef].ID)
      var Patch = this.CookiesPatcher(originalSubArea, NewSubArea)
      this.Post('PATCH', 'Area/EditSubArea?ID=' + SubArea.ID, Patch).then(response => {
        this.ReadSubAreas()
        this.ReadLines()
        this.ReadMainAreas()
      }, error => {
        error.CookiesError = 'Error in updating SubArea Line'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveSubArea (SubArea) {
      this.OpenDialog('DeleteDialog', 'Deleting SubArea', ['DeleteSubArea?ID=' + SubArea.ID, SubArea.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    SubAreaLineSelector (SubArea) {
      return this.IndexFinder(this.CookiesDataLines, ['ID'], this.DeepFetcher(SubArea, ['LineID']))
    },
    SubAreaMainAreaSelector (SubArea) {
      return this.IndexFinder(this.CookiesDataAreas, ['ID'], this.DeepFetcher(SubArea, ['AreaID']))
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadSubAreas()
      this.ReadLines()
    }
  }
}
</script>
<style scoped>

.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 1rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  margin: auto;
  height: unset;
}
@media (max-width: 500px) {
  .BigHeader>>>*{
    font-size: 1.5em !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
.TableBodyContainer{
  width: 100%;
  overflow-y: unset;
  margin-top: 0em;
  min-width: 60vw;
  padding: 0;
}
td {
  padding: 0;
}
</style>
